<template>
<svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle
    cx="19"
    cy="19"
    r="18"
    fill="#32827C"
    stroke="white"
    stroke-width="2"
  />
  <path
    d="M18.5 10C14.3562 10 11 10.4737 11 13.7895V23.2631C11 24.1016 11.3656 24.8453 11.9375 25.3663V27.0526C11.9375 27.5737 12.3594 28 12.875 28H13.8125C14.3328 28 14.75 27.5737 14.75 27.0526V26.1053H22.25V27.0526C22.25 27.5737 22.6672 28 23.1875 28H24.125C24.6406 28 25.0625 27.5737 25.0625 27.0526V25.3663C25.6344 24.8453 26 24.1016 26 23.2632V13.7895C26 10.4737 22.6438 10 18.5 10ZM14.2813 24.2105C13.5031 24.2105 12.875 23.5758 12.875 22.7895C12.875 22.0032 13.5031 21.3684 14.2813 21.3684C15.0594 21.3684 15.6875 22.0032 15.6875 22.7895C15.6875 23.5758 15.0594 24.2105 14.2813 24.2105ZM22.7188 24.2105C21.9407 24.2105 21.3126 23.5758 21.3126 22.7895C21.3126 22.0032 21.9407 21.3684 22.7188 21.3684C23.497 21.3684 24.1251 22.0032 24.1251 22.7895C24.1251 23.5758 23.4969 24.2105 22.7188 24.2105ZM24.1251 18.5263H12.875V13.7895H24.1251V18.5263Z" fill="white"
  />
</svg>
</template>
