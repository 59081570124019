
import MapStops from '@/components/RealTime/MapStops.vue'
import Map from '@/components/RealTime/Map.vue'
import PageHeader from '@/components/PageHeader.vue'
import {
  getDepartureDetailByStop
} from '@/expressway-api/busStops.api'
import { RTPIDeparture, RTPIStop, MapPoint, MapStop } from '@/models/RtpiDeparture'
import { computed, defineComponent, onBeforeUnmount, ref } from 'vue'
import decodePolyline from 'decode-google-map-polyline'
import Spinner from '@/components/Spinner.vue'
import NoTrackingInfo from '@/components/NoTrackingInfo.vue'
import ListIcon from '@/components/RealTime/Icons/ListIcon.vue'
import Close from '@/components/vectors/Close.vue'
import GenericError from '@/components/GenericError.vue'
export default defineComponent({
  name: 'StopsSearchMap',
  components: {
    GenericError,
    MapStops,
    Map,
    PageHeader,
    Spinner,
    NoTrackingInfo,
    ListIcon,
    Close
  },
  props: {
    date: {
      type: String,
      required: true
    },
    serviceNumber: {
      type: String,
      required: true
    },
    time: {
      type: String,
      required: true
    },
    direction: {
      type: String,
      required: true
    },
    stopId: {
      type: String,
      required: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const selectedStop = ref<MapStop>()
    const departure = ref<RTPIDeparture>()
    const zoom = ref(8)
    const showMap = ref(false)
    const error = ref(false)
    const centerOfMap = ref<MapPoint>()
    const showMobileMenu = ref('')
    getDepartureDetailByStop(
      props.date,
      props.serviceNumber,
      props.time,
      props.direction,
      props.stopId
    // eslint-disable-next-line complexity
    ).then((response: RTPIDeparture) => {
      departure.value = response
      if (response.Stops && response.Stops.length > 0) {
        const centerStop = response.Stops[Math.floor(response.Stops.length / 2)]
        centerOfMap.value = {
          lat: centerStop.Wgs84LatitudeDegrees,
          lng: centerStop.Wgs84LongitudeDegrees
        } as MapPoint
        showMap.value = true
        setTimeout(() => scrollToBus(), 500)
      } else {
        error.value = true
      }
    }).catch(() => {
      error.value = true
    })
    const setSelectedBusStop = (stop: MapStop) => {
      selectedStop.value = stop
    }
    // eslint-disable-next-line complexity
    const markStop = (stop: RTPIStop) => {
      selectedStop.value = {
        Id: stop.Id,
        StartPoint: false,
        EndPoint: false,
        Position: {
          lat: stop.Wgs84LatitudeDegrees ?? 0,
          lng: stop.Wgs84LongitudeDegrees ?? 0
        }
      }
      centerOfMap.value = {
        lat: stop.Wgs84LatitudeDegrees ?? 0,
        lng: stop.Wgs84LongitudeDegrees ?? 0
      }
      showMobileMenu.value = 'hide'
    }
    const formatStops = computed(() => {
      if (departure.value?.Stops) {
        const stops = [...departure.value.Stops]
        const formattedStops = stops.map((s: RTPIStop, index: number) => {
          const stopFormatedMap = {
            StartPoint: index === 0,
            EndPoint: (stops.length - 1) === (index),
            Id: s.Id,
            Position: {
              lat: s.Wgs84LatitudeDegrees,
              lng: s.Wgs84LongitudeDegrees
            }
          } as MapStop
          return stopFormatedMap
        })
        return formattedStops
      }
      return [] as MapStop[]
    })
    const travelledPolylines = computed(() => {
      const polylines: MapPoint[] = []
      if (departure.value?.Stops) {
        const stops = [...departure.value?.Stops]
        const isMovingTo = departure?.value?.Tracking?.IsMovingToStopSequence ||
          departure?.value?.Tracking?.IsArrivedAtStopSequence || 0
        stops.forEach(stop => {
          if (stop.Sequence < isMovingTo - 1 &&
              stop.GeometryToNextStop) {
            polylines.push(...decodePolyline(stop.GeometryToNextStop))
          }
        })
      }
      return polylines
    })
    const toTravellPolylines = computed(() => {
      const polylines: MapPoint[] = []
      if (departure.value?.Stops) {
        const stops = [...departure.value?.Stops]
        const isMovingTo = departure?.value?.Tracking?.IsMovingToStopSequence ||
          departure?.value?.Tracking?.IsArrivedAtStopSequence || 0
        stops.forEach(stop => {
          if (stop.Sequence >= isMovingTo - 1 &&
              stop.GeometryToNextStop) {
            polylines.push(...decodePolyline(stop.GeometryToNextStop))
          }
        })
      }
      return polylines
    })

    const scrollToBus = () => {
      const [stopsWrapper] = document.getElementsByClassName('stops-wrapper')
      const [bus] = document.getElementsByClassName('bus-icon-box')
      const stopsWrapperOffset = stopsWrapper.getBoundingClientRect().top
      const offset = bus.getBoundingClientRect().top - stopsWrapperOffset - 40

      stopsWrapper.scrollTo(0, offset)
    }

    const departuresInterval = setInterval(() => {
      getDepartureDetailByStop(
        props.date,
        props.serviceNumber,
        props.time,
        props.direction,
        props.stopId
      // eslint-disable-next-line complexity
      ).then((response: RTPIDeparture) => {
        if (departure.value) {
          departure.value.ActiveVehicle = response?.ActiveVehicle
          departure.value.Tracking = response?.Tracking
        }
      })
    }, 30000)

    onBeforeUnmount(() => {
      clearInterval(departuresInterval)
    })

    return {
      error,
      zoom,
      markStop,
      setSelectedBusStop,
      showMobileMenu,
      travelledPolylines,
      toTravellPolylines,
      centerOfMap,
      formatStops,
      selectedStop,
      departure,
      showMap
    }
  }
})
