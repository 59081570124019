<template>
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="#C2002F"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.39069 0.015625L5.79065 4.82613L10.1906 0.015625L11.5422 1.49659L5.79065 7.79856L0.0390625 1.49659L1.39069 0.015625Z"
      fill-rule="evenodd"
    />
  </svg>
</template>
