
import {
  computed,
  defineComponent,
  ref
} from 'vue'
import MapStops from '@/components/RealTime/MapStops.vue'
import PageHeader from '@/components/PageHeader.vue'
import {
  getDepartureDetailByStop
} from '@/expressway-api/busStops.api'
import { RTPIDeparture } from '@/models/RtpiDeparture'
import Spinner from '@/components/Spinner.vue'
import NoTrackingInfo from '@/components/NoTrackingInfo.vue'
import GenericError from '@/components/GenericError.vue'

export default defineComponent({
  components: {
    MapStops,
    PageHeader,
    Spinner,
    NoTrackingInfo,
    GenericError
  },
  props: {
    date: {
      required: true,
      type: String
    },
    serviceNumber: {
      required: true,
      type: String
    },
    time: {
      required: true,
      type: String
    },
    direction: {
      required: true,
      type: String
    },
    stopId: {
      required: true,
      type: String
    }
  },
  setup (props) {
    const departure = ref<RTPIDeparture>()
    const loading = ref(true)
    const heading = ref('')
    const error = ref(false)

    getDepartureDetailByStop(
      props.date,
      props.serviceNumber,
      props.time,
      props.direction,
      props.stopId
    ).then((response: RTPIDeparture) => {
      departure.value = response
      if (!departure.value.Stops || departure.value.Stops.length === 0) {
        error.value = true
      } else {
        heading.value = `Track Route ${departure.value.Trip.RouteId}`
        loading.value = false
      }
    }).catch(() => {
      error.value = true
    })

    const routeMap = computed(() => ({
      name: 'Track Stops Map',
      params: {
        serviceNumber: props.serviceNumber,
        time: props.time,
        direction: props.direction,
        stopId: props.stopId
      }
    }))

    // eslint-disable-next-line complexity
    const duration = computed((): string => {
      if (departure.value &&
          departure.value.Trip.ArrivalTimeUnix &&
          departure.value.Trip.DepartureTimeUnix) {
        const duration = new Date(
          (departure.value.Trip.ArrivalTimeUnix - departure.value.Trip.DepartureTimeUnix) * 1000
        )

        const hours = duration.getHours() - 1
        const minutes = duration.getMinutes()
        let message = ''

        if (hours === 1) {
          message += `${hours} hour `
        } else if (hours > 0) {
          message += `${hours} hours `
        }

        if (minutes > 0) {
          message += `${minutes} mins`
        }

        return message
      } else {
        return 'unkown'
      }
    })

    setInterval(() => {
      getDepartureDetailByStop(
        props.date,
        props.serviceNumber,
        props.time,
        props.direction,
        props.stopId
      ).then((response: RTPIDeparture) => {
        if (departure.value) {
          departure.value.Tracking = response?.Tracking
        }
      })
    }, 30000)

    return {
      error,
      routeMap,
      departure,
      loading,
      duration,
      heading
    }
  }
})
