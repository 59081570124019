
import Map from '@/components/RealTime/Map.vue'
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import PageHeader from '@/components/PageHeader.vue'
import { defineComponent, ref, computed, watch } from 'vue'
import { fetchRTPINearByStops } from '@/expressway-api/busStops.api'
import router from '@/router'
import { NavigatorLocation, RTPIBusStop } from '@/models/BusStop'
import { MapStop, MapPoint } from '@/models/RtpiDeparture'
import MapLocation from '@/components/RealTime/Icons/MapLocation.vue'
import GenericError from '@/components/GenericError.vue'
import Spinner from '@/components/Spinner.vue'
import { setSnackbar } from '@/composables/useSnackbar'

// This function takes in latitude and longitude of two location
// and returns the distance between them as the crow flies (in km)
const calcCrow = (lat1: number, lon1: number, lat2: number, lon2: number): number => {
  const R = 6371 // km
  const dLat = toRad(lat2 - lat1)
  const dLon = toRad(lon2 - lon1)
  const lat1N = toRad(lat1)
  const lat2N = toRad(lat2)

  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1N) * Math.cos(lat2N)
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d = R * c
  return d
}

// Converts numeric degrees to radians
const toRad = (Value: number) :number => Value * Math.PI / 180

export default defineComponent({
  name: 'FindStops',
  components: {
    GmapCustomMarker,
    Map,
    PageHeader,
    MapLocation,
    GenericError,
    Spinner
  },
  // eslint-disable-next-line max-lines-per-function
  setup () {
    const activeSelectedStop = ref<MapStop>()
    const geoLocationAvailable = ref(true)
    const centerOfMap = ref<MapPoint>({
      lat: 53.349921,
      lng: -6.2542221
    } as MapPoint)
    const lastSearch = ref<MapPoint>({
      lat: 53.349921,
      lng: -6.2542221
    } as MapPoint)
    const zoom = ref(13)
    const radius = ref(8000)
    const minZoom = ref(zoom.value)
    const gettingLocation = ref(true)
    const currentLocation = ref<NavigatorLocation>()
    const geolocationErrorMessage = ref('')
    const stops = ref<RTPIBusStop[]>([])
    const goBackToCenter = () => {
      zoom.value = 13
      if (currentLocation.value) {
        centerOfMap.value = {
          lat: currentLocation.value.latitude,
          lng: currentLocation.value.longitude
        }
      }
      setSelectedBusStop(undefined)
    }
    const fetchCurrentLocation = async () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(async pos => {
          centerOfMap.value = {
            lat: pos.coords.latitude,
            lng: pos.coords.longitude
          } as MapPoint
          currentLocation.value = {
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
            current: true,
            radius: radius.value
          }
          await fetchStops()
          gettingLocation.value = false
        }, () => {
          geolocationErrorMessage.value = 'Error Loading Location'
          geoLocationAvailable.value = false
          gettingLocation.value = false
          router.push({
            name: 'Rtpi'
          }).then(() => {
            setSnackbar('Geolocation is not enabled. Please enable to use this feature.', 'danger')
          })
        })
      } else {
        router.push({
          name: 'Rtpi'
        }).then(() => {
          setSnackbar('Geolocation is not enabled. Please enable to use this feature.', 'danger')
        })
      }
    }
    const fetchStops = async () => {
      if (currentLocation.value) {
        const currentLocationParam = { ...currentLocation.value }
        currentLocationParam.radius = radius.value
        currentLocationParam.latitude = centerOfMap.value.lat
        currentLocationParam.longitude = centerOfMap.value.lng
        lastSearch.value.lat = currentLocationParam.latitude
        lastSearch.value.lng = currentLocationParam.longitude
        await fetchRTPINearByStops(currentLocationParam).then(responseStops => {
          responseStops.forEach(newStop => {
            if (!stops.value?.some(s => s.UTrackId === newStop.UTrackId)) {
              stops.value?.push(newStop)
            }
          })
        })
      }
    }
    fetchCurrentLocation()
    const formatStops = computed(() => {
      if (stops.value) {
        const formattedStops = stops.value.map((s: RTPIBusStop) => {
          const stopFormatedMap = {
            Id: Number(s.UTrackId),
            StartPoint: false,
            EndPoint: false,
            Position: {
              lat: Number(s.Latitude),
              lng: Number(s.Longitude)
            }
          } as MapStop
          return stopFormatedMap
        })
        return formattedStops
      }
      return [] as MapStop[]
    })
    const setSelectedBusStop = (selectedStop: MapStop | undefined) => {
      activeSelectedStop.value = selectedStop
    }
    const selectedStopExtended = computed(() =>
      stops.value?.find(stop => Number(stop.UTrackId) === activeSelectedStop.value?.Id))
    const goToRoute = (utrackId: string) => {
      router.push({ name: 'rtpi Departures List', params: { stopId: utrackId } })
    }
    const userLocation = computed(() => ({
      lat: currentLocation.value?.latitude,
      lng: currentLocation.value?.longitude
    } as MapPoint))
    watch(zoom, () => {
      if (zoom.value < minZoom.value && minZoom.value >= 7) {
        minZoom.value = zoom.value
        fetchStops()
      }
    })
    watch(centerOfMap, val => {
      const distanceFromLastSearch =
        calcCrow(val.lat, val.lng, lastSearch.value.lat, lastSearch.value.lng)
      // must be improved using the visible area to calculate radius
      if (distanceFromLastSearch > 6 && zoom.value > 8 && minZoom.value > 7) {
        fetchStops()
      }
    })
    const updatedRadius = (newRadius: number) => {
      radius.value = newRadius
    }
    return {
      radius,
      updatedRadius,
      gettingLocation,
      geolocationErrorMessage,
      goBackToCenter,
      zoom,
      userLocation,
      goToRoute,
      formatStops,
      centerOfMap,
      activeSelectedStop,
      setSelectedBusStop,
      selectedStopExtended,
      geoLocationAvailable
    }
  }
})
