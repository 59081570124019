<template>
<button class="list">
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_27067_17870)">
      <path
        d="M23.2536 12.7947H6.95337C6.43559 12.7947 6.01587 12.375 6.01587 11.8572C6.01587 11.3394 6.43559 10.9197 6.95337 10.9197H23.2536C23.7714 10.9197 24.1911 11.3394 24.1911 11.8572C24.1911 12.375 23.7714 12.7947 23.2536 12.7947Z" fill="#3C5FA3"
      />
      <path
        d="M23.2536 19.8259H6.95337C6.43559 19.8259 6.01587 19.4062 6.01587 18.8884C6.01587 18.3706 6.43559 17.9509 6.95337 17.9509H23.2536C23.7714 17.9509 24.1911 18.3706 24.1911 18.8884C24.1911 19.4062 23.7714 19.8259 23.2536 19.8259Z" fill="#3C5FA3"
        />
      <path
        d="M23.2536 5.76343H6.95337C6.43559 5.76343 6.01587 5.34371 6.01587 4.82593C6.01587 4.30815 6.43559 3.88843 6.95337 3.88843H23.2536C23.7714 3.88843 24.1911 4.30815 24.1911 4.82593C24.1911 5.34371 23.7714 5.76343 23.2536 5.76343Z" fill="#3C5FA3"
      />
      <path
        d="M1.45679 17.6228C2.15577 17.6228 2.72241 18.1894 2.72241 18.8884C2.72241 19.5874 2.15577 20.1541 1.45679 20.1541C0.757802 20.1541 0.191162 19.5874 0.191162 18.8884C0.191162 18.1894 0.757802 17.6228 1.45679 17.6228Z" fill="#3C5FA3"
      />
      <path
        d="M1.45679 10.5916C2.15577 10.5916 2.72241 11.1582 2.72241 11.8572C2.72241 12.5562 2.15577 13.1228 1.45679 13.1228C0.757802 13.1228 0.191162 12.5562 0.191162 11.8572C0.191162 11.1582 0.757802 10.5916 1.45679 10.5916Z" fill="#3C5FA3"
      />
      <path
        d="M1.45679 3.5603C2.15577 3.5603 2.72241 4.12694 2.72241 4.82593C2.72241 5.52491 2.15577 6.09155 1.45679 6.09155C0.757802 6.09155 0.191162 5.52491 0.191162 4.82593C0.191162 4.12694 0.757802 3.5603 1.45679 3.5603Z" fill="#3C5FA3"
      />
    </g>
    <defs>
      <clipPath id="clip0_27067_17870">
        <rect width="24" height="24" fill="white" transform="matrix(1 0 0 -1 0.191162 23.8572)"/>
      </clipPath>
    </defs>
  </svg>
</button>
</template>

<style lang="scss" scoped>
.list {
  width: 67px;
  height: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--map-button-bg-color);
  border-radius: 100%;
  overflow: hidden;

  svg {
    margin: auto;
    width: 24px;
    height: 24px;
  }
}
</style>
