<template>
<svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle opacity="0.1" cx="42.5" cy="42.5" r="42.5" fill="#456FB4"/>
  <g filter="url(#filter0_d_26054_266518)">
    <circle cx="42" cy="43" r="12" fill="#456FB4"/>
    <circle cx="42" cy="43" r="10" stroke="white" stroke-width="4"/>
  </g>
  <defs>
    <filter
      id="filter0_d_26054_266518"
      x="6"
      y="11"
      width="72"
      height="72"
      filterUnits="userSpaceOnUse"
      color-interpolation-filters="sRGB"
    >
      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="4"/>
      <feGaussianBlur stdDeviation="12"/>
      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"/>
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_26054_266518"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_26054_266518"
        result="shape"
      />
    </filter>
  </defs>
</svg>
</template>
